import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HomeOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  LineChartOutlined,
  FundProjectionScreenOutlined,
  MoreOutlined,
  CommentOutlined
} from '@ant-design/icons';
import './SiderComponent.css';

const SiderComponent: React.FC = () => {
  return (
    <Menu mode="inline" theme="light">
      <Menu.Item key="1" icon={<HomeOutlined />}>
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<AppstoreOutlined />}>
        <Link to="/">Applications</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CalendarOutlined />} disabled={true}>
        <Link to="/">Events</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<LineChartOutlined />} disabled={true}>
        <Link to="/">Insights</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<FundProjectionScreenOutlined />} disabled={true}>
        <Link to="/">Models</Link>
      </Menu.Item>
      <Menu.Item key="sandbox" icon={<CommentOutlined />}>
        <Link to="/sandbox">Sandbox</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<MoreOutlined />} disabled={true}>
        <Link to="/">More</Link>
      </Menu.Item>
    </Menu>
  );
}

export default SiderComponent;


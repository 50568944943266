class SentinelDirective {
    id: number;
    sentinel_id: number;
    date_created: number;
    directive_body: any;
    examples: any[];

    constructor() {
        this.id = -1
        this.sentinel_id = -1
        this.date_created = -1
        this.directive_body = {}
        this.examples = []
    }
}

export default SentinelDirective;


import EventSubscription from "./EventSubscription";

class Webhook {
    id: number;
    date_created: number;
    url: string;
    company_id: number;
    status: string;
    event_subscriptions: EventSubscription[];

    constructor() {
        this.id = -1
        this.date_created = -1
        this.url = ''
        this.company_id = -1
        this.status = ''
        this.event_subscriptions = []
    }
  };

  export default Webhook;

import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Spin, Card, List, Collapse, Pagination, Row, Typography, Space, Timeline, Col, Tag, Table, Descriptions, Divider, Statistic, Button, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { CaretRightOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { ApplicationService } from '../services/ApplicationService';
import Application from '../models/Application';
import Session from '../models/Session';
import EvalRequest from '../models/EvalRequest';
import EvalResult from '../models/EvalResult';
import { EvaluationService } from '../services/EvaluationService';
import EvalResults from '../models/EvalResults';
import moment from 'moment';
import './ApplicationOverview.css';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { usePermissions } from '../PermissionsProvider';

type TableRowSelection<T> = TableProps<T>['rowSelection'];
type ExpandableConfig<T extends object> = TableProps<T>['expandable'];

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;
const defaultExpandable: ExpandableConfig<EvalResults> = {
  expandedRowRender: (record: EvalResults) => {
    if (record.evaluation_result?.description) {
      return <p>{record.evaluation_result.description}</p>;
    }
    return null;
  },
  rowExpandable: (record: EvalResults) => !!record.evaluation_result?.description,
};

const ApplicationOverview: React.FC = () => {
  const { apiKey } = usePermissions();
  const { applicationId } = useParams<{ applicationId: string }>();
  const [application, setApplication] = useState<Application | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Adjust based on your preference
  const wsRef = useRef<ReconnectingWebSocket | null>(null);
  const lastMessageTimeRef = useRef<Date>(new Date());

  useEffect(() => {
    if (applicationId) {
      setIsLoading(true)
      ApplicationService.getApplication(Number(applicationId), null, apiKey)
        .subscribe((app: Application) => {
          setApplication(app);
        }, (error: any) => {
          console.error(error);
        });

      EvaluationService.getApplicationSessions(applicationId, (currentPage - 1) * pageSize, pageSize, setIsLoading, apiKey)
        .subscribe((sessions: Session[]) => {
          setSessions(sessions);
        }, (error: any) => {
          console.error(error);
          setIsLoading(false);
        });
      }
      return openWebsocket();
    }, [applicationId, currentPage]);

  const openWebsocket = () => {
    closeWsConnection();

    const ws: any = new ReconnectingWebSocket(`wss://humrvommw4.execute-api.us-west-2.amazonaws.com/production?key=${applicationId}&type=EVAL_RESULTS_PACKAGE`);
    wsRef.current = ws;

    ws.addEventListener('message', (message: any) => {
      const data = JSON.parse(message.data);
      handleNewEvalRequest(data);
    });

    // create an interval that checks the last message time and refreshes connection if needed
    const checkInterval = setInterval(() => {
      const now = new Date();
      // Check the payments WS
      const diffInMinutesWS = (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);

      if (diffInMinutesWS >= 1) { // adjust time as needed
        if (wsRef.current) {
          wsRef.current.reconnect(1000, 'Manual reconnect');
          lastMessageTimeRef.current = new Date();
        }
      }

    }, 1000 * 5); // check every 5 seconds

    console.log('Opened WS connection');

    return () => {
      if (checkInterval) clearInterval(checkInterval);
      closeWsConnection()
    };
  }

  const closeWsConnection = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
      console.log('Closed WS connection');
    }
  };


  const handleNewEvalRequest = (data: any) => {
    console.log('Received new eval request', data);
    const newEvalRequest: EvalRequest = data.event_data;
    const newEvalResults: EvalResults[] = newEvalRequest.eval_results_set;
    const sessionId = newEvalRequest.session_id;

    setSessions((prevSessions) => {
      let sessionExists = false;
      const updatedSessions = prevSessions.map(session => {
        if (session.session_id === sessionId) {
          sessionExists = true;
          // Check if this session already has the eval request
          const existingEvalRequestIndex = session.eval_requests.findIndex(er => er.id === newEvalRequest.id);
          if (existingEvalRequestIndex > -1) {
            // Update existing eval request with new results, ensuring no duplicates
            const existingEvalResults = session.eval_requests[existingEvalRequestIndex].eval_results_set;
            const updatedEvalResults = [...existingEvalResults];
            newEvalResults.forEach(newResult => {
              const resultIndex = existingEvalResults.findIndex(result => result.id === newResult.id);
              if (resultIndex === -1) {
                updatedEvalResults.push(newResult); // Add new result if it doesn't exist
              } else {
                updatedEvalResults[resultIndex] = newResult; // Update existing result
              }
            });
            session.eval_requests[existingEvalRequestIndex].eval_results_set = updatedEvalResults;
          } else {
            // Add new eval request if it doesn't exist in the session
            session.eval_requests.push(newEvalRequest);
          }
          return { ...session }; // Return a new object to ensure React state update
        }
        return session; // Return unchanged session
      });

      // If the session_id does not exist in current sessions, then create a new session
      if (!sessionExists) {
        const newSession: Session = {
          date_created: newEvalRequest.date_created,
          application_id: Number(applicationId),
          session_id: sessionId,
          eval_requests: [newEvalRequest],
        };
        // Add the new session to the beginning of the sessions array
        updatedSessions.splice(0, 0, newSession);
      }

      return updatedSessions;
    });
  };

  const getStatusMetricsTags = (evalResults: EvalResults[]) => {
    const faultCount = evalResults.reduce((acc, { evaluation_result }) => {
      if (evaluation_result?.status === 'FAULT') {
        acc += 1;
      }
      return acc;
    }, 0);

    if (faultCount > 0) {
      return <Tag color="error">{`${faultCount} FAULT`}</Tag>;
    }
    return null;
  };

  const calculateScore = (session: Session): number => {
    let totalResults = 0;
    let faultCount = 0;

    session.eval_requests.forEach(evalRequest => {
      totalResults += 1;
      // If the eval request has a FAULT at all, count it
      faultCount += evalRequest.eval_results_set.some(evalResult => evalResult.evaluation_result?.status === 'FAULT') ? 1 : 0;
    });

    const faultRatio = faultCount / totalResults;
    const score = 100 * (1 - faultRatio);

    return Math.max(0, Math.round(score));
  };

  const getSessionScoreColor = (session: Session) => {
    const score = calculateScore(session);
    if (isNaN(score)) return 'default';
    if (score >= 90) return 'success';
    if (score >= 60) return 'warning';
    return 'error';
  }

  const sessionColumns: TableColumnsType<Session> = [
    {
      title: 'Session ID',
      dataIndex: 'session_id',
      key: 'session_id',
    },
    {
      title: 'Score',
      key: 'score',
      render: (_, session: Session) => (
        <Tag color={getSessionScoreColor(session)}>{calculateScore(session)}</Tag>
      )
    },
    {
      title: 'Date Started',
      dataIndex: 'date_created',
      key: 'date_created',
      render: (text: string) => moment(text).format('YYYY-MM-DD hh:mm:ss A'),
    },
    {
      title: 'Duration',
      key: 'duration',
      render: (_, session: Session) => {
        const numberOfRequests = session.eval_requests.length;
        const firstRequestTime = session.eval_requests[0]?.date_created ? moment(session.eval_requests[0].date_created) : null;
        const lastRequestTime = session.eval_requests[numberOfRequests - 1]?.date_created ? moment(session.eval_requests[numberOfRequests - 1].date_created) : null;
        const duration = lastRequestTime && firstRequestTime ? moment.duration(lastRequestTime.diff(firstRequestTime)).humanize() : 'N/A';
        return duration;
      },
    },
    {
      title: '# Requests',
      key: 'requests',
      render: (_, session: Session) => session.eval_requests.length,
    },
    {
      title: 'Faults',
      key: 'faults',
      render: (_, session: Session) => {
        const faultsCount = session.eval_requests.reduce((acc, evalRequest) => {
          const hasFault = evalRequest.eval_results_set.some(evalResult => evalResult.evaluation_result?.status === 'FAULT');
          return acc + (hasFault ? 1 : 0);
        }, 0);
        return faultsCount;
      },
    },
    // Add more columns as needed
  ];

  const columns: TableColumnsType<EvalResults> = [
    {
      title: 'Sentinel Name',
      dataIndex: ['sentinel', 'sentinel_name'],
      width: '40%'
    },
    {
      title: 'Status',
      dataIndex: ['evaluation_result', 'status'],
      width: '20%',
      render: (status: string) => {
        let color = 'default';
        if (status === 'PASS') {
          color = 'success';
        } else if (status === 'FAULT') {
          color = 'error';
        }
        return <Tag color={color}>{status}</Tag>;
      }
    },
    {
      title: 'Confidence',
      dataIndex: ['evaluation_result', 'confidence'],
      width: '20%'
    },
    {
      title: 'Feedback',
      dataIndex: '',
      key: 'feedback',
      width: '20%',
      render: (_, record: EvalResults) => (
        <Space>
          <Button type="text" icon={<LikeOutlined />} />
          <Button type="text" icon={<DislikeOutlined />} />
        </Space>
      )
    }
  ];

  const expandableEvalRequest = {
    expandedRowRender: (record: EvalResults) => {
      if (record.evaluation_result?.description) {
        return <p>{record.evaluation_result.description}</p>;
      }
      return null;
    },
    rowExpandable: (record: EvalResults) => !!record.evaluation_result?.description,
  };

  const expandableSession = {
    expandedRowRender: (session: Session) => {
      return (
        <Timeline
          pending="Listening..."
          reverse={true}
          >
            {session.eval_requests.map((evalRequest: EvalRequest) => {
              const faultTag = getStatusMetricsTags(evalRequest.eval_results_set);
              return (
                <Timeline.Item key={evalRequest.reference_id} dot={moment(evalRequest.date_created).format('HH:mm:ss')}>
                  <Collapse bordered={false} defaultActiveKey={[]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                    <Panel header={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {faultTag && <div style={{ marginRight: 8 }}>{faultTag}</div>}
                        <Tag style={{ marginRight: 8 }}>{evalRequest.action_type}</Tag>
                        <Typography>{evalRequest.evaluation_content}</Typography>
                      </div>
                    } key="1">
                      <Table 
                      rowKey={record => record.id}
                      style={{marginLeft: '24px'}} 
                      {...tableProps} 
                      dataSource={evalRequest.eval_results_set} />
                    </Panel>
                  </Collapse>
                </Timeline.Item>
              );
            })}
          </Timeline>
      );
    },
  };

  const tableProps: TableProps<EvalResults> = {
    columns,
    expandable: expandableEvalRequest,
    pagination: false
  };

  const navigate = useNavigate();
  const enableApplication = (applicationId: string | undefined) => {
    ApplicationService.enableApplication(Number(applicationId), null, apiKey).subscribe(() => {
      setApplication((prevApplication) => prevApplication ? { ...prevApplication, state: 'ACTIVE' } : null);
    }, (error: any) => {
      console.error(error);
    });
  };

  const disableApplication = (applicationId: string | undefined) => {
    ApplicationService.disableApplication(Number(applicationId), null, apiKey).subscribe(() => {
      setApplication((prevApplication) => prevApplication ? { ...prevApplication, state: 'DISABLED' } : null);
    }, (error: any) => {
      console.error(error);
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => navigate(`/application/${applicationId}/edit`)}>Edit</Menu.Item>
      {application?.state === 'DISABLED' && (
        <Menu.Item key="2" onClick={() => enableApplication(applicationId)}>Enable</Menu.Item>
      )}
      {(application?.state === 'ENABLED' || application?.state === 'ACTIVE') && (
        <Menu.Item key="3" onClick={() => disableApplication(applicationId)}>Disable</Menu.Item>
      )}
    </Menu>
  );

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <div className="applications-container">
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Link to="/"><Typography style={{color: 'blue', fontSize: '16px', fontWeight: '600'}}>Applications</Typography></Link>
        </Col>
        <Col>
          <Dropdown overlay={menu}>
            <Button shape="default" icon={<EllipsisOutlined />} />
          </Dropdown>
        </Col>
      </Row>
      <Space direction="vertical" size={10} style={{ width: '100%' }} split={<Divider type="horizontal" />}>
        {application ? (
          <div>
            <Title level={4}>{application.application_name}</Title>
            <Descriptions colon={false} column={12} layout='vertical'>
              <Descriptions.Item label="Application ID" span={3}>{application.id}</Descriptions.Item>
              <Descriptions.Item label="State" span={2}>
                <Tag color={application.state === 'ACTIVE' ? 'success' : application.state === 'DISABLED' ? 'error' : 'default'}>
                  {application.state}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Date Created" span={3}>{moment(application.date_created).format('YYYY-MM-DD hh:mm:ss A')}</Descriptions.Item>
              <Descriptions.Item label="Secret" span={2}>None</Descriptions.Item>
              <Descriptions.Item label="Configuration" span={2}>View</Descriptions.Item>
            </Descriptions>
          </div>
        ) : (
          <Paragraph>Application not found</Paragraph>
        )}
        <Space direction="vertical" size="small" style={{width: '100%'}}>
          <Title level={4}>Health</Title>
          <Space direction="horizontal" size={30} style={{width: '100%'}} split={<Divider type="vertical" />}>
            <Statistic title="Responsibility" value={99} />
            <Statistic title="Quality" value={94} />
            <Statistic title="Coverage" value={"89%"} />
            <Statistic title="Sessions" value={sessions.length} />
          </Space>
        </Space>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <Title level={4}>Sessions</Title>
          <Table
            rowKey={"session_id"}
            columns={sessionColumns}
            dataSource={sessions}
            expandable={expandableSession}
            pagination={false}
          />
        </Space>
      </Space>
    </div>
  );
};

export default ApplicationOverview

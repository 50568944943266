import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Spin, Space, Tooltip, Row, Col, Dropdown, Menu, notification } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, StopOutlined, CheckCircleOutlined, PlusOutlined, DeleteOutlined, SearchOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import Application from '../models/Application';
import Sentinel from '../models/Sentinel';
import { ApplicationService } from '../services/ApplicationService';
import { SentinelService } from '../services/SentinelService';
import { useNavigate } from 'react-router-dom';
import './Applications.css';
import { usePermissions } from '../PermissionsProvider';

type ApplicationColumns = ColumnsType<Application>;
type SentinelColumns = ColumnsType<Sentinel>;

const Applications: React.FC = () => {
    const { apiKey } = usePermissions();
    const [applications, setApplications] = useState<Application[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const applicationColumns: ApplicationColumns = [
      {
        title: 'Application Name',
        dataIndex: 'application_name',
        key: 'application_name',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: state => <Tag color={state === 'ACTIVE' ? 'success' : state === 'PENDING' ? 'default' : 'error'}>{state}</Tag>,
        sorter: (a, b) => a.state.localeCompare(b.state),
      },
      {
        title: 'Date Created',
        dataIndex: 'date_created',
        key: 'date_created',
        render: date => moment(date).format('YYYY-MM-DD hh:mm:ss A'),
        sorter: (a, b) => moment(a.date_created).unix() - moment(b.date_created).unix(),
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Button.Group>
            <Tooltip title="View Application">
              <Button icon={<SearchOutlined />} onClick={(e) => {
                e.stopPropagation(); // Prevent row onClick from being triggered
                navigate(`/application/${record.id}`);
              }} />
            </Tooltip>
            <Tooltip title="Edit Application">
              <Button icon={<EditOutlined />} onClick={(e) => {
                e.stopPropagation(); // Prevent row onClick from being triggered
                navigate(`/application/${record.id}/edit`);
              }} />
            </Tooltip>
            <Tooltip title="Clone Application"> {/* Added Clone button */}
              <Button icon={<CopyOutlined />} onClick={(e) => {
                e.stopPropagation(); // Prevent row onClick from being triggered
                navigate(`/application/${record.id}/clone`);
              }} />
            </Tooltip>
            {record.state === 'ACTIVE' || record.state === 'ENABLED' ? (
              <Tooltip title="Disable Application">
                <Button icon={<StopOutlined />} onClick={(e) => {
                  e.stopPropagation(); // Prevent row onClick from being triggered
                  disableApplication(record.id);
                }} />
              </Tooltip>
            ) : (
              <Tooltip title="Enable Application">
                <Button icon={<CheckCircleOutlined />} onClick={(e) => {
                  e.stopPropagation(); // Prevent row onClick from being triggered
                  enableApplication(record.id);
                }} />
              </Tooltip>
            )}
            <Tooltip title="Add Sentinel">
              <Button icon={<PlusOutlined />} onClick={(e) => {
                e.stopPropagation(); // Prevent row onClick from being triggered
                navigate(`/application/${record.id}/sentinel/new`);
              }} />
            </Tooltip>
          </Button.Group>
        ),
      },
    ];

    useEffect(() => {
      refreshApplications();
    }, []);
  
  
    const refreshApplications = () => {
      setIsLoading(true);
      ApplicationService.getApplications(setIsLoading, apiKey).subscribe((apps: Application[]) => {
        setApplications(apps);
        setIsLoading(false);
      }, (error: any) => {
        console.error(error);
        setIsLoading(false);
      });
    };
  
    const disableApplication = (applicationId: number) => {
      setIsLoading(true);
      ApplicationService.disableApplication(applicationId, setIsLoading, apiKey).subscribe((application: Application) => {
        const updatedApplications = applications.map((app) => app.id === application.id ? application : app);
        setApplications(updatedApplications);
      }, (error: any) => {
        console.error(error);
        setIsLoading(false);
      });
    };
  
    const enableApplication = (applicationId: number) => {
      setIsLoading(true);
      ApplicationService.enableApplication(applicationId, setIsLoading, apiKey).subscribe((application: Application) => {
        const updatedApplications = applications.map((app) => app.id === application.id ? application : app);
        setApplications(updatedApplications);
      }, (error: any) => {
        console.error(error);
        setIsLoading(false);
      });
    };

    // Define a separate component for the expanded row content
    const ExpandedApplicationRow: React.FC<{ applicationId: number }> = ({ applicationId }) => {
      const [sentinels, setSentinels] = useState<Sentinel[]>([]);
      const [isLoading, setIsLoading] = useState<boolean>(true);

      const getSentinels = (appId: number) => {
        setIsLoading(true);
        SentinelService.getSentinels(appId, setIsLoading, apiKey).subscribe((sents: Sentinel[]) => {
          setSentinels(sents);
        }, (error: any) => {
          console.error(error);
          setIsLoading(false);
        });
      };
  
      const deleteSentinel = (sentinelId: number) => {
        setIsLoading(true);
        SentinelService.deleteSentinel(sentinelId, setIsLoading, apiKey).subscribe((sentinel: Sentinel) => {
          const updatedSentinels = sentinels.filter((sent) => sent.id !== sentinel.id);
          setSentinels(updatedSentinels);
        }, (error: any) => {
          console.error(error);
          setIsLoading(false);
        });
      }
    
      const disableSentinel = (sentinelId: number) => {
        setIsLoading(true);
        SentinelService.disableSentinel(sentinelId, setIsLoading, apiKey).subscribe((sentinel: Sentinel) => {
          const updatedSentinels = sentinels.map((sent) => sent.id === sentinel.id ? sentinel : sent);
          setSentinels(updatedSentinels);
          setIsLoading(false);
        }, (error: any) => {
          console.error(error);
          setIsLoading(false);
        });
      };
    
      const enableSentinel = (sentinelId: number) => {
        setIsLoading(true);
        SentinelService.enableSentinel(sentinelId, setIsLoading, apiKey).subscribe((sentinel: Sentinel) => {
          const updatedSentinels = sentinels.map((sent) => sent.id === sentinel.id ? sentinel : sent);
          setSentinels(updatedSentinels);
          setIsLoading(false);
        }, (error: any) => {
          console.error(error);
          setIsLoading(false);
        });
      };

      const cloneSentinel = (sentinelId: number, applicationId: number) => {
        setIsLoading(true);
        SentinelService.cloneSentinel(sentinelId, applicationId, setIsLoading, apiKey).subscribe((sentinel: Sentinel) => {
          const updatedSentinels = sentinels.map((sent) => sent.id === sentinel.id ? sentinel : sent);
          setSentinels(updatedSentinels);
          notification.success({
            message: 'Success',
            description: 'Sentinel cloned successfully',
          });
        }, (error: any) => {
          console.error(error);
          setIsLoading(false);
        });
      };

      const sentinelColumns: SentinelColumns = [
        {
          title: 'Sentinel Name',
          dataIndex: 'sentinel_name',
          key: 'sentinel_name',
        },
        {
          title: 'State',
          dataIndex: 'state',
          key: 'state',
          render: state => <Tag color={state === 'ACTIVE' ? 'success' : state === 'DISABLED' ? 'error' : 'default'}>{state}</Tag>,
        },
        {
          title: 'Date Created',
          dataIndex: 'date_created',
          key: 'date_created',
          render: date => moment(date).format('YYYY-MM-DD hh:mm:ss A'),
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Button.Group>
              <Tooltip title="Edit Sentinel">
                <Button icon={<EditOutlined />} onClick={(e) => {
                  e.stopPropagation(); // Prevent row onClick from being triggered
                  navigate(`/application/${record.application_id}/sentinel/${record.id}`);
                }} />
              </Tooltip>
              <Tooltip title="Clone Sentinel">
                        <Dropdown overlay={
                            <Menu onClick={(e) => {
                                // Handle menu item click, e.key is the applicationId
                                console.log(`Clone sentinel ${record.id} to application ${e.key}`);
                                cloneSentinel(record.id, Number(e.key));
                            }}>
                                {applications.filter(app => app.id !== record.application_id).map((app) => (
                                    <Menu.Item key={app.id}>{app.application_name}</Menu.Item>
                                ))}
                            </Menu>
                        } trigger={['click']}>
                            <Button icon={<CopyOutlined />} onClick={(e) => e.stopPropagation()} />
                        </Dropdown>
                    </Tooltip>
              {record.state === 'ACTIVE' || record.state === 'ENABLED' ? (
                <Tooltip title="Disable Sentinel">
                  <Button icon={<StopOutlined />} onClick={(e) => {
                    e.stopPropagation(); // Prevent row onClick from being triggered
                    disableSentinel(record.id);
                  }} />
                </Tooltip>
              ) : (
                <Tooltip title="Enable Sentinel">
                  <Button icon={<CheckCircleOutlined />} onClick={(e) => {
                    e.stopPropagation(); // Prevent row onClick from being triggered
                    enableSentinel(record.id);
                  }} />
                </Tooltip>
              )}
              <Tooltip title="Delete Sentinel">
                <Button icon={<DeleteOutlined />} onClick={(e) => {
                  e.stopPropagation(); // Prevent row onClick from being triggered
                  deleteSentinel(record.id);
                }} />
              </Tooltip>
            </Button.Group>
          ),
        },
      ];

      useEffect(() => {
        getSentinels(applicationId);
      }, [applicationId]);

      if (isLoading) {
        return <Spin />;
      }

      return <Table columns={sentinelColumns} dataSource={sentinels} rowKey="id" pagination={false} />;
    };

    // Inside your Applications component, update the expandable configuration
    const expandable = {
      expandedRowRender: (record: Application) => <ExpandedApplicationRow applicationId={record.id} />,
      rowExpandable: () => true,
    };

    return (
      <div className="applications-container" style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <Row justify="space-between" align="middle">
            <Col>
              <h1>Applications</h1>
            </Col>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('/application/new')}>
                New Application
              </Button>
            </Col>
          </Row>
            {isLoading ? (
              <Spin size="large" style={{ display: 'flex', justifyContent: 'center', padding: '20px' }} />
            ) : (
              <Table
                rowKey="id"
                columns={applicationColumns}
                dataSource={applications}
                expandable={expandable}
                pagination={false}
              />
            )}
          </Space>
      </div>
    );
}

export default Applications;


import React, { useEffect, useState } from 'react';
import { Input, Menu, Layout } from 'antd';
import { SearchOutlined, QuestionCircleOutlined, BellOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './HeaderComponent.css';
import { usePermissions } from './PermissionsProvider';
import { CompanyService } from './services/CompanyService';
import Company from './models/Company';

const { Header } = Layout;

const HeaderComponent: React.FC = () => {
    const { apiKey } = usePermissions();
    const [company, setCompany] = useState<Company | null>(null);

    useEffect(() => {
        if (apiKey) {
            CompanyService.getCompany(null, apiKey).subscribe((result: Company) => {
                console.log(result)
                setCompany(result)
            }, (error: any) => {
                console.log(error)
            })
        }
    }, [apiKey])

    return (
        <Header className="Header">
            <div className="leftSection" style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                <div className="logo" style={{ color: 'black', fontWeight: 'bold', marginRight: '80px' }}>{company?.company_name}</div>
                <Input prefix={<SearchOutlined />} className="searchInput" placeholder="Search..." />
            </div>
            <Menu mode="horizontal" theme="light" style={{ lineHeight: '64px', background: 'transparent', border: 'none', width: '40%', justifyContent: 'flex-end' }}>
                <Menu.Item key="developers">
                    <Link to="/developers">Developers</Link>
                </Menu.Item>
                <Menu.Item key="help" icon={<QuestionCircleOutlined />} disabled={true}>
                    <Link to="/">Help</Link>
                </Menu.Item>
                <Menu.Item key="alerts" icon={<BellOutlined />} disabled={true}>
                    <Link to="/">Alerts</Link>
                </Menu.Item>
                <Menu.Item key="settings" icon={<SettingOutlined />} disabled={true}>
                    <Link to="/">Settings</Link>
                </Menu.Item>
            </Menu>
        </Header>
    );
}

export default HeaderComponent;

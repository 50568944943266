import SentinelDirective from "./SentinelDirective";

enum SeverityLevel {
    INFO = 0,
    WARN = 1,
    ERROR = 2,
    FATAL = 3
}

class Sentinel {
    id: number;
    date_created: number;
    application_id: number;
    sentinel_name: string;
    sentinel_directive: SentinelDirective | null;
    state: string;
    action_types: string[];
    severity: number;

    constructor() {
        this.id = 0
        this.date_created = 0
        this.application_id = 0
        this.sentinel_name = ''
        this.sentinel_directive = null
        this.state = ''
        this.action_types = []
        this.severity = 0
    }
  };

  export default Sentinel;
  export { SeverityLevel };



import React, { useEffect, useState } from 'react';
import { Table, Button, Tag } from 'antd';
import { Line, LineConfig } from '@ant-design/charts';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { WebhookService } from '../services/WebhookService';
import Webhook from '../models/Webhook';
import { usePermissions } from '../PermissionsProvider';

const DevelopersWebhooks: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { apiKey } = usePermissions();
  const [webhooks, setWebhooks] = useState<any[]>([]); // Define state variable

  useEffect(() => { // Use useEffect to load data on mount
    WebhookService.getWebhooks(null, apiKey).subscribe((data: Webhook[]) => {
      const augmentedData = data.map(webhook => ({
        ...webhook,
        // Augment the data with mock fields
        type: 'user', // Mock data, you might want to vary this based on some criteria
        errorRate: '5.6%', // Mock data
        last7DaysData: [ // Mock data
          { day: 'Day 1', value: 3 },
          { day: 'Day 2', value: 4 },
          { day: 'Day 3', value: 1 },
          { day: 'Day 4', value: 5 },
          { day: 'Day 5', value: 2 },
          { day: 'Day 6', value: 3 },
          { day: 'Day 7', value: 4 },
        ],
      }));
      setWebhooks(augmentedData); // Update state with augmented data
    }, (error: any) => {
      console.error('Error fetching webhooks:', error);
    });
  }, []); // Empty dependency array means this effect runs once on mount

  const columns = [
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Last 7 Days',
      dataIndex: 'last7Days',
      key: 'last7Days',
      render: (text: any, record: any) => {
        const config = {
          data: record.last7DaysData,
          xField: 'day',
          yField: 'value',
          height: 50,
          width: 200,
          autoFit: false,
          smooth: true,
          lineStyle: {
            lineWidth: 2,
            stroke: '#1890ff',
          },
          axis: false,
          tooltip: false
        };
        return <Line {...config} />;
      },
    },
    {
      title: 'Error Rate',
      dataIndex: 'errorRate',
      key: 'errorRate',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        let color = 'default';
        if (status === 'ACTIVE') color = 'success';
        else if (status === 'DISABLED') color = 'error';
        // Assuming ENABLED should also be handled as per user's context
        else if (status === 'ENABLED') color = 'default';

        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h2>Hosted Endpoints</h2>
        <Button type="primary" onClick={() => navigate('/developers/webhooks/create')}>+ Add endpoint</Button>
      </div>
      <Table size="small" columns={columns} dataSource={webhooks} bordered={false} pagination={false}/>
    </div>
  );
};

export default DevelopersWebhooks;


import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import RegisterSentinel from './sentinel/RegisterSentinel';
import Applications from './application/Applications';
import EditSentinel from './sentinel/EditSentinel';
import './App.css';
import EditApplication from './application/EditApplication';
import ApplicationOverview from './application/ApplicationOverview';
import DevelopersWebhooksCreate from './developers/DevelopersWebhooksCreate';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import HeaderComponent from './HeaderComponent';
import Sider from 'antd/es/layout/Sider';
import SiderComponent from './SiderComponent';
import DevelopersBase from './developers/DevelopersBase';
import Sandbox from './sandbox/Sandbox';
import RegisterApplication from './application/RegisterApplication';
import { useAuth0 } from '@auth0/auth0-react';
import jwt, { jwtDecode } from 'jwt-decode';
import { PermissionsProvider } from './PermissionsProvider';
import CloneApplication from './application/CloneApplication';

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Layout style={{minHeight: '100vh'}}>
      <Header className='Header'>
        <HeaderComponent />
      </Header>
      <Layout>
        <Sider width={200} className='Sider'>
          <SiderComponent />
        </Sider>
        <Content style={{background: '#fff', padding: '24px 16px 0', overflow: 'initial'}}>
                   {children}
        </Content>
      </Layout>
    </Layout>
  );
};


const App: React.FC = () => {
  const location = useLocation();

  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
  const domain = 'dev-way2b5h4kq0hxc1j.us.auth0.com';
  const [userMeta, setUserMeta] = useState<any>([]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${domain}/api/v2/`,
          scope: "openid profile email read:current_user",
        }
      })
      .then((accessToken) => {
        console.log(`AccessToken: ${accessToken}`)
        if (!accessToken) {
          console.error('No access token returned');
          return;
        }
        // Use the access token to fetch user profile from the /userinfo endpoint
        fetch(`https://${domain}/api/v2/users/${user?.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => response.json())
        .then((profile) => {
          console.log('User Profile:', profile);
          // Access user_metadata from the profile
          console.log('User Metadata:', profile['user_metadata']);
          setUserMeta(profile['user_metadata'])
          // You can now set this information in your state or use it as needed
        })
        .catch((err) => {
          console.error('Error fetching user profile', err);
          logout({logoutParams: {returnTo: window.location.origin }}).then(() => {
            loginWithRedirect();
        });
        });
      })
      .catch((err) => {
        console.error('Error getting access token', err);
        if (err.error === 'consent_required') {
          logout({logoutParams: {returnTo: window.location.origin }}).then(() => {
              loginWithRedirect();
          });
      }
      });
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout]);

  useEffect(() => {
    if (!userMeta || !userMeta.api_key) {
      // logout({logoutParams: {returnTo: window.location.origin }}).then(() => {
      //     loginWithRedirect();
      // });
    } else {
      console.log('userMeta', userMeta)
    }
  }, [userMeta, logout]);

  console.log('user', user)

  return (
    <>
    {userMeta && userMeta.api_key !== undefined ? (
      <PermissionsProvider apiKey={userMeta.api_key}>
        <Routes>
          <Route path="/" element={<AppLayout><Applications /></AppLayout>} />
          <Route path="/application/:applicationId" element={<AppLayout><ApplicationOverview /></AppLayout>} />
          <Route path='/application/new' element={<AppLayout><RegisterApplication /></AppLayout>} />
          <Route path="/application/:applicationId/edit" element={<AppLayout><EditApplication /></AppLayout>} />
          <Route path="/application/:applicationId/clone" element={<AppLayout><CloneApplication /></AppLayout>} />
          <Route path="/application/:applicationId/sentinel/new" element={<AppLayout><RegisterSentinel /></AppLayout>} />
          <Route path="/application/:applicationId/sentinel/:sentinelId" element={<AppLayout><EditSentinel /></AppLayout>} />
          <Route path="/developers" element={<AppLayout><DevelopersBase /></AppLayout>} />
          <Route path="/developers/webhooks/create" element={<DevelopersWebhooksCreate />} />
          <Route path="/sandbox" element={<AppLayout><Sandbox /></AppLayout>} />
          </Routes>
        </PermissionsProvider>
      ) : (
        null
      )
    }
    </>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);

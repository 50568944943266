import React from 'react';
import { Space, Tabs } from 'antd';
import DevelopersOverview from './DevelopersOverview';
import DevelopersAPIKeys from './DevelopersAPIKeys';
import DevelopersWebhooks from './DevelopersWebhooks';
import DevelopersIntegrations from './DevelopersIntegrations';
import DevelopersLogs from './DevelopersLogs';

const { TabPane } = Tabs;

const DevelopersBase: React.FC = () => {
  return (
    <div>
      <h1>Developers</h1>
      <Space  direction="vertical" size="small" style={{width: '100%'}}>
        <Tabs defaultActiveKey="1">
        <TabPane tab="Overview" key="1">
            <DevelopersOverview />
        </TabPane>
        <TabPane tab="API Keys" key="2">
            <DevelopersAPIKeys />
        </TabPane>
        <TabPane tab="Webhooks" key="3">
            <DevelopersWebhooks />
        </TabPane>
        <TabPane tab="Integrations" key="4">
            <DevelopersIntegrations />
        </TabPane>
        <TabPane tab="Logs" key="5">
            <DevelopersLogs />
        </TabPane>
        </Tabs>
      </Space>
    </div>
  );
};

export default DevelopersBase;

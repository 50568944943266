import React from 'react';
import { Card } from 'antd';

const DevelopersIntegrations: React.FC = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Card title="Integrations" bordered={false}>
        <p>Content coming soon...</p>
      </Card>
    </div>
  );
};

export default DevelopersIntegrations;

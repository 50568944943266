import React from 'react';
import { Card } from 'antd';

const DevelopersLogs: React.FC = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Card title="Logs" bordered={false}>
        <p>Content coming soon...</p>
      </Card>
    </div>
  );
};

export default DevelopersLogs;

class ApplicationObjective {
    id: number;
    application_id: number;
    date_created: number;
    objective_body: any;

    constructor() {
        this.id = -1
        this.date_created = -1
        this.application_id = -1
        this.objective_body = {}
    }
}

export default ApplicationObjective;


import React from 'react';
import { Card } from 'antd';

const DevelopersOverview: React.FC = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Card title="Developers Overview" bordered={false}>
        <p>Content coming soon...</p>
      </Card>
    </div>
  );
};

export default DevelopersOverview;

import React, { useState } from 'react';
import { Button, Card, Col, Input, Row, Form, Typography, Space, Divider, Tabs, Select, Tag, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import './DevelopersWebhooksCreate.css';
import { CloseOutlined } from '@ant-design/icons';
import { CopyBlock, monokaiSublime } from 'react-code-blocks';
import Webhook from '../models/Webhook';
import { WebhookService } from '../services/WebhookService';
import { usePermissions } from '../PermissionsProvider';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const DevelopersWebhooksCreate: React.FC = () => {
  const navigate = useNavigate();
  const { apiKey } = usePermissions();
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [form] = Form.useForm(); 
  const [loading, setLoading] = useState<boolean>(false); // Added loading state


  const handleSelectEvent = (value: string) => {
    if (!selectedEvents.includes(value)) {
      setSelectedEvents([...selectedEvents, value]);
    }
  };

  const handleDeselectEvent = (value: string) => {
    setSelectedEvents(selectedEvents.filter(event => event !== value));
  };

  const codeString = `import flask
from flask import request

app = flask.Flask(__name__)

@app.route('/webhook', methods=['POST'])
def webhook():
    data = request.json
    print("Received webhook:", data)

    ## Handle the event
    if event['event_type'] == 'EVAL_RESULTS_UPDATE':
        eval_results = event['event_data']
        print("Evaluation results updated:", eval_results)

    return "Webhook received", 200

if __name__ == '__main__':
    app.run(port=5000)`;

    const handleSubmit = async () => {
      setLoading(true); // Set loading to true at the start of submission
      try {
        // Trigger form validation and get form values
        const values = await form.validateFields();
        
        // Create a new Webhook instance with form data
        const webhook = new Webhook();
        webhook.url = values.endpointURL; // Ensure the form item name matches
        webhook.event_subscriptions = selectedEvents.map(event => ({ event_type: event }));

        console.log(`Webhook to be created: ${JSON.stringify(webhook)}`)
        
        // Call the registerWebhook service method
        WebhookService.registerWebhook(webhook, setLoading, apiKey) // setLoading is to manage loading state
          .subscribe((wh: Webhook) => {
              // Handle successful webhook registration
              console.log('Webhook registered successfully', wh);
              notification.success({
                message: 'Success',
                description: 'Webhook registered successfully',
              });
              navigate('/developers'); // Redirecting to the Applications page
            }, (error: any) => {
              // Handle error in webhook registration
              console.error('Failed to register webhook', error);
              notification.error({
                message: 'Error',
                description: 'Failed to register webhook',
              });
              setLoading(false); // Set loading to false after completion
            },
          );
      } catch (errorInfo) {
        console.log('Failed to submit form:', errorInfo);
        setLoading(false); // Set loading to false after completion
      }
    };

  return (
    <div className="webhooks-create-container">
      <Space direction="vertical" style={{width: '100%'}}>
        <Space direction="horizontal" size={15} style={{width: '100%'}} split={<Divider type="vertical" />}>
          <Button className="close-button" type={'text'} onClick={() => navigate(-1)}><CloseOutlined /></Button>
          <Typography>Listen to MaiTai Events</Typography>
        </Space>
        <Row gutter={16} style={{width: '100%', padding: '0px 45px'}}>
          <Col span={12}>
            <Title level={4}>Listen to MaiTai Events</Title>
            <Paragraph>Set up your webhook endpoint to receive live events from MaiTai.</Paragraph>
            <Form layout="vertical" form={form} disabled={loading}>
              <Form.Item name="endpointURL" 
              label="Endpoint URL" 
              required 
              tooltip="This is the URL where events will be sent."
                rules={[
                  { required: true, message: 'Please enter a valid URL' },
                  { type: 'url', message: 'Please enter a valid URL' },
                ]}
              >
                <Input placeholder="https://..."/>
              </Form.Item>
              <Form.Item name="description" 
              label="Description"
                rules={[
                  { required: true, message: 'Please enter a description' },
                  { max: 100, message: 'Description cannot be longer than 100 characters.'},
                ]}
              >
                <Input.TextArea placeholder="An optional description of what this webhook endpoint is used for..."/>
              </Form.Item>
              <Paragraph>Select events to listen to</Paragraph>
              <Form.Item name="selectedEvents"
                          rules={[
                            {
                              required: true,
                              message: 'Please select at least one event',
                              validator: (_, value) =>
                                value && value.length > 0 ? Promise.resolve() : Promise.reject(new Error('Please select at least one event')),
                            },
                          ]}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select Events"
                  value={selectedEvents}
                  onSelect={handleSelectEvent}
                  onDeselect={handleDeselectEvent}
                  tagRender={tagProps => {
                    const { label, value, closable, onClose } = tagProps;
                    return (
                      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                        {label}
                      </Tag>
                    );
                  }}
                >
                  <Option value="EVAL_RESULTS_PACKAGE">Evaluation Results</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmit} loading={loading}>Add Endpoint</Button>
                <Button type="default" onClick={() => navigate(-1)} style={{ marginLeft: '8px' }} disabled={loading}>Cancel</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Sample Endpoint" key="1">
                <CopyBlock
                    text={codeString}
                    language="python"
                    showLineNumbers={true}
                    theme={monokaiSublime}
                    codeBlock={true}
                  />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Received Events" key="2">
                {/* Content for Received Events will go here */}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default DevelopersWebhooksCreate;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Form, Input, Card, notification, List } from 'antd';
import { ApplicationService } from '../services/ApplicationService';
import Application from '../models/Application';
import './RegisterApplication.css';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationObjective from '../models/ApplicationObjective';
import { usePermissions } from '../PermissionsProvider';


const RegisterApplication: React.FC = () => {
  const navigate = useNavigate();
  const { apiKey } = usePermissions();
  const contextEntryIdCounter = useRef(0);
  const [contextEntries, setContextEntries] = useState<{ id: number; context_key: string; context_value: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [applicationRefName, setApplicationRefName] = useState('');
  const [objective, setObjective] = useState('');

  useEffect(() => {

  }, []);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    const application: Application = new Application();
    application.application_name = applicationName;
    application.application_ref_name = applicationRefName; // Adjust the field name as necessary
    application.application_objective = new ApplicationObjective();
    application.application_objective.objective_body = { main: objective };

    ApplicationService.registerApplication(application, null, apiKey).subscribe(
      (app: Application) => {
        const updatedContextBody = contextEntries.reduce(
          (acc, { context_key, context_value }) => ({ ...acc, [context_key]: context_value }),
          {}
        );
        const payload = { application_id: app.id, context: updatedContextBody };
        ApplicationService.updateApplicationContext(payload, setLoading, apiKey).subscribe(() => {
          notification.success({
            message: 'Success',
            description: 'Application created successfully',
          });
          navigate(`/application/${app.id}`);
        }, (error: any) => {
          console.error(error);
          notification.error({
            message: 'Error',
            description: 'Created application, but failed to set context',
          });
        });
      },
      (error: any) => {
        console.error(error);
        notification.error({
          message: 'Error',
          description: 'Failed to create application',
        });
        setLoading(false);
      }
    );
  }, [applicationName, applicationRefName, objective, navigate]);

  const handleContextKeyChange = useCallback((itemId: number, newContextKey: string) => {
    setContextEntries((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, context_key: newContextKey } : entry))
    );
  }, []);

  const handleContextValueChange = useCallback((itemId: number, newContextValue: string) => {
    setContextEntries((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, context_value: newContextValue } : entry))
    );
  }, []);

  const handleRemoveContextEntry = useCallback((itemId: number) => {
    setContextEntries((prev) => prev.filter((entry) => entry.id !== itemId));
  }, []);

  const handleAddContextEntry = useCallback(() => {
    setContextEntries((prev) => [
      ...prev,
      { id: Date.now() + Math.random(), context_key: '', context_value: '' },
    ]);
  }, []);

  return (
    <div className="register-application-container">
      <Card title="Register Application" bordered={false} className="register-application-card">
        <Form layout="vertical" disabled={loading}>
          <Form.Item label="Name" required tooltip="The name of the application">
            <Input value={applicationName} onChange={e => setApplicationName(e.target.value)} placeholder="My Application" />
          </Form.Item>
          <Form.Item label="Application Reference" required tooltip="The reference name of the application">
            <Input value={applicationRefName} onChange={e => setApplicationRefName(e.target.value)} placeholder="my_application" />
          </Form.Item>
          <Form.Item label="Objective" required tooltip="The objective of the application">
            <Input.TextArea value={objective} onChange={e => setObjective(e.target.value)} rows={4} placeholder="The purpose of this application is to do this thing." />
          </Form.Item>
          <Form.Item label="Application Context" required>
            <List
              dataSource={contextEntries}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Input
                    style={{ width: '30%', marginRight: '1em' }}
                    value={item.context_key}
                    onChange={(e) => handleContextKeyChange(item.id, e.target.value)}
                  />
                  <Input.TextArea
                    style={{ width: '65%', marginRight: '1em' }}
                    value={item.context_value}
                    onChange={(e) => handleContextValueChange(item.id, e.target.value)}
                    rows={1}
                  />
                  <Button onClick={() => handleRemoveContextEntry(item.id)}>-</Button>
                </List.Item>
              )}
            />
            <List.Item>
              <Button type="dashed" onClick={handleAddContextEntry} block>
                + Add Context Entry
              </Button>
            </List.Item>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit} block loading={loading} disabled={loading}>Create Application</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default RegisterApplication;

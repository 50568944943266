import React, { useState, useEffect, useRef, useCallback } from 'react'; // Import useEffect
import { Button, Form, Input, Card, notification, Space, List, Select, Tag } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { SentinelService } from '../services/SentinelService';
import Sentinel from '../models/Sentinel';
import { useNavigate, useParams } from 'react-router-dom';
import SentinelDirective from '../models/SentinelDirective';
import './EditSentinel.css'; 
import { usePermissions } from '../PermissionsProvider';
import { SeverityLevel } from '../models/Sentinel';

const EditSentinel: React.FC = () => {
  const navigate = useNavigate();
  const { apiKey } = usePermissions();
  const { applicationId, sentinelId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sentinelName, setSentinelName] = useState('');
  const [directive, setDirective] = useState('');
  const [sentinel, setSentinel] = useState<Sentinel | undefined>();
  const [examples, setExamples] = useState<{ id: number; exampleType: string; inputExample: string }[]>([]);
  const exampleIdCounter = useRef(0);
  const [actionTypes, setActionTypes] = useState<string[]>([]);
  const [severity, setSeverity] = useState<SeverityLevel>(SeverityLevel.INFO);

  useEffect(() => {
    if (sentinelId) {
      setLoading(true);
      SentinelService.getSentinel(Number(sentinelId), setLoading, apiKey)
        .subscribe((sentinel: Sentinel) => {
          setSentinel(sentinel);
          setSentinelName(sentinel.sentinel_name);
          setDirective(sentinel.sentinel_directive?.directive_body?.['main']);
          const fetchedExamples = sentinel.sentinel_directive?.examples?.map((example, index) => ({
            id: index, // or any unique identifier
            exampleType: example.example_type,
            inputExample: example.example_body,
          })) || [];
          setExamples(fetchedExamples);
          exampleIdCounter.current = fetchedExamples.length;
          setActionTypes(sentinel.action_types);
          setSeverity(sentinel.severity);
          setLoading(false);
        }, (error: any) => {
          console.error(error);
          notification.error({
            message: 'Error',
            description: 'Failed to fetch sentinel',
          });
          setLoading(false);
        });
    }
  }, [sentinelId]); // Dependency array includes sentinelId to refetch if it changes

  const handleAddExample = useCallback(() => {
    setExamples((prev) => [
      ...prev,
      { id: exampleIdCounter.current++, exampleType: '', inputExample: '' },
    ]);
  }, []);

  const handleRemoveExample = useCallback((itemId: number) => {
    setExamples((prev) => prev.filter((entry) => entry.id !== itemId));
  }, []);

  const handleExampleTypeChange = useCallback((itemId: number, newType: string) => {
    setExamples((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, exampleType: newType } : entry))
    );
  }, []);

  const handleInputExampleChange = useCallback((itemId: number, newInput: string) => {
    setExamples((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, inputExample: newInput } : entry))
    );
  }, []);

  const handleActionTypeChange = (value: string[]) => {
    // Uppercase all of the action types
    const uppercaseActionTypes = value.map(actionType => actionType.toUpperCase());
    setActionTypes(uppercaseActionTypes);
  };

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    return (
      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    if (sentinel) {
        sentinel.sentinel_name = sentinelName;
        if (!sentinel.sentinel_directive) {
          sentinel.sentinel_directive = new SentinelDirective();
        }
        sentinel.sentinel_directive.directive_body = { 'main': directive };
        sentinel.sentinel_directive.examples = examples.map(example => ({
          example_type: example.exampleType,
          example_body: example.inputExample
      }));
      sentinel.action_types = actionTypes; // Add action types to the sentinel object
      sentinel.severity = severity;
    }
    console.log(sentinel);

    SentinelService.updateSentinel(sentinel, setLoading, apiKey).subscribe(() => {
        notification.success({
          message: 'Success',
          description: 'Sentinel updated successfully',
        });
        navigate('/'); // Redirecting to the Applications page
      }, (error: any) => {
        console.error(error);
        notification.error({
          message: 'Error',
          description: 'Failed to update sentinel',
        });
        setLoading(false);
      });
  };

  return (
    <div className="edit-sentinel-container">
      <Card title="Edit Sentinel" bordered={false} className="edit-sentinel-card">
        <Form layout="vertical" disabled={loading}>
          <Form.Item label="Name" required tooltip="Your name for this Sentinel">
            <Input value={sentinelName} onChange={e => setSentinelName(e.target.value)} placeholder="Menu Monitor" />
          </Form.Item>
          <Form.Item label="Directive" required tooltip="Tells the sentinel what to monitor for">
            <Input.TextArea value={directive} onChange={e => setDirective(e.target.value)} rows={4} placeholder="Make sure the customer doesn't order items that aren't on the menu"/>
          </Form.Item>
          <Form.Item label="Examples" required>
            <List
              dataSource={examples}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Select
                    style={{ width: '30%', marginRight: '1em' }}
                    value={item.exampleType}
                    onChange={(value) => handleExampleTypeChange(item.id, value)}
                    placeholder="Select type"
                  >
                    <Select.Option value="PASS" style={{color: 'green'}}><span style={{color: 'green'}}>PASS</span></Select.Option>
                    <Select.Option value="FAULT" style={{color: 'red'}}><span style={{color: 'red'}}>FAULT</span></Select.Option>
                    <Select.Option value="NA" style={{color: 'grey'}}><span style={{color: 'grey'}}>NA</span></Select.Option>
                  </Select>
                  <Input
                    style={{ width: '65%', marginRight: '1em' }}
                    value={item.inputExample}
                    onChange={(e) => handleInputExampleChange(item.id, e.target.value)}
                    placeholder="Example"
                  />
                  <Button onClick={() => handleRemoveExample(item.id)}>-</Button>
                </List.Item>
              )}
            />
            <List.Item>
              <Button type="dashed" onClick={handleAddExample} block>
                + Add Example
              </Button>
            </List.Item>
          </Form.Item>
          <Form.Item label="Action Types" required tooltip="Defines the types of actions this Sentinel can take">
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Add Action Types"
              value={actionTypes}
              onChange={handleActionTypeChange}
              tagRender={tagRender}
              maxLength={20} // This does not apply to Select in 'tags' mode, custom validation needed for each tag
            />
          </Form.Item>
          <Form.Item label="Severity" required tooltip="Select the severity level for this Sentinel">
            <Select value={severity} onChange={value => setSeverity(value)}>
              {Object.entries(SeverityLevel).filter(([key, value]) => !isNaN(Number(value))).map(([key, value]) => (
                <Select.Option key={value} value={value}>{key}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit} block loading={loading} disabled={loading}>Update Sentinel</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default EditSentinel;

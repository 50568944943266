import ApplicationObjective from "./ApplicationObjective";

class Application {
    id: number;
    date_created: number;
    application_name: string;
    application_ref_name: string;
    company_id: number;
    application_objective: ApplicationObjective | null;
    state: string;

    constructor() {
        this.id = -1
        this.date_created = -1
        this.application_name = ''
        this.application_ref_name = ''
        this.company_id = -1
        this.application_objective = null
        this.state = ''
    }
  };

  export default Application;



import Application from '../models/Application'
import GeneralService from './GeneralService'
import {Component} from 'react'


export class ApplicationService extends Component {
  public static registerApplication(application: Application, loadingHandler: any, apiKey: string) {
    return GeneralService.post(`/application/`, JSON.stringify(application), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static cloneApplication(clone_application_id: any, application: any, loadingHandler: any, apiKey: string) {
    const payload = {'clone_application_id': clone_application_id, 'application': application}
    return GeneralService.post(`/application/clone`, JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }
  
  public static getApplications(loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/application/`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static enableApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/${applicationId}/enable`, '', loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static disableApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/${applicationId}/disable`, '', loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static getApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/application/${applicationId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static updateApplication(application: Application | undefined, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/`, JSON.stringify(application), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static getApplicationContext(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/context/application/${applicationId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static updateApplicationContext(payload: any, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/context/application`, JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }
}


import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Form, Input, Card, notification, List } from 'antd';
import { ApplicationService } from '../services/ApplicationService';
import Application from '../models/Application';
import './CloneApplication.css';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationObjective from '../models/ApplicationObjective';
import { usePermissions } from '../PermissionsProvider';


const CloneApplication: React.FC = () => {
  const navigate = useNavigate();  
  const { apiKey } = usePermissions();
  const contextEntryIdCounter = useRef(0);
  const [contextEntries, setContextEntries] = useState<{ id: number; context_key: string; context_value: string }[]>([]);
  const { applicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [applicationRefName, setApplicationRefName] = useState('');
  const [objective, setObjective] = useState('');
  const [application, setApplication] = useState<Application | undefined>();
  const [applicationClone, setApplicationClone] = useState<Application | undefined>();

  useEffect(() => {
    if (applicationId) {
        setLoading(true);
        ApplicationService.getApplication(Number(applicationId), setLoading, apiKey)
        .subscribe((application: Application) => {
          setApplication(application);
          setObjective(application.application_objective?.objective_body['main'] || '');
          const applicationClone = new Application();
          const new_application_name = application.application_name + '  (Clone)';
          const new_application_ref_name = application.application_ref_name + ' (Clone)';
          applicationClone.company_id = application.company_id;
          applicationClone.application_name = new_application_name;
          applicationClone.application_ref_name = new_application_ref_name;
          applicationClone.application_objective = application.application_objective;
          if (applicationClone.application_objective !== null) {
            applicationClone.application_objective.id = -1
            applicationClone.application_objective.application_id = -1
          }
          setApplicationName(new_application_name);
          setApplicationRefName(new_application_ref_name);
          setApplicationClone(applicationClone);
          setLoading(false);
        }, (error: any) => {
          console.error(error);
          notification.error({
            message: 'Error',
            description: 'Failed to fetch application',
          });
          setLoading(false);
        });
        ApplicationService.getApplicationContext(Number(applicationId), null, apiKey)
            .subscribe((context: any) => {
                setContextEntries(
                    Object.entries(context.context_body || {}).map(([context_key, context_value]) => ({
                      id: contextEntryIdCounter.current++,
                      context_key,
                      context_value: String(context_value),
                    })));
                }, (error: any) => {
                console.error(error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch application context',
                });
        });
    }
  }, [applicationId]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    if (application && applicationClone) {
      applicationClone.application_name = applicationName;
      applicationClone.application_ref_name = applicationRefName;
      if (!applicationClone.application_objective) {
        applicationClone.application_objective = new ApplicationObjective();
      }
      applicationClone.application_objective.objective_body = { main: objective };
    }

    ApplicationService.cloneApplication(applicationId, applicationClone, setLoading, apiKey).subscribe(
      (app: Application) => {
        const updatedContextBody = contextEntries.reduce(
          (acc, { context_key, context_value }) => ({ ...acc, [context_key]: context_value }),
          {}
        );
        const payload = { application_id: app.id, context: updatedContextBody };
        ApplicationService.updateApplicationContext(payload, setLoading, apiKey).subscribe(() => {
          notification.success({
            message: 'Success',
            description: 'Application cloned successfully',
          });
          navigate(`/application/${app.id}`);
        }, (error: any) => {
          console.error(error);
          notification.error({
            message: 'Error',
            description: 'Cloned application, but failed to set context',
          });
          setLoading(false);
        });
        notification.success({
          message: 'Success',
          description: 'Application cloned successfully',
        });
        navigate('/');
      },
      (error: any) => {
        console.error(error);
        notification.error({
          message: 'Error',
          description: 'Failed to clone application',
        });
        setLoading(false);
      }
    );
  }, [application, applicationName, applicationRefName, objective, contextEntries, applicationId, navigate]);

  const handleContextKeyChange = useCallback((itemId: number, newContextKey: string) => {
    setContextEntries((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, context_key: newContextKey } : entry))
    );
  }, []);

  const handleContextValueChange = useCallback((itemId: number, newContextValue: string) => {
    setContextEntries((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, context_value: newContextValue } : entry))
    );
  }, []);

  const handleRemoveContextEntry = useCallback((itemId: number) => {
    setContextEntries((prev) => prev.filter((entry) => entry.id !== itemId));
  }, []);

  const handleAddContextEntry = useCallback(() => {
    setContextEntries((prev) => [
      ...prev,
      { id: Date.now() + Math.random(), context_key: '', context_value: '' },
    ]);
  }, []);

  return (
    <div className="clone-application-container">
      <Card title="Clone Application" bordered={false} className="clone-application-card">
        <Form layout="vertical" disabled={loading}>
          <Form.Item label="Name" required tooltip="The name of the application">
            <Input value={applicationName} onChange={e => setApplicationName(e.target.value)} placeholder="My Application" />
          </Form.Item>
          <Form.Item label="Application Reference" required tooltip="The reference name of the application">
            <Input value={applicationRefName} onChange={e => setApplicationRefName(e.target.value)} placeholder="my_application" />
          </Form.Item>
          <Form.Item label="Objective" required tooltip="The objective of the application">
            <Input.TextArea value={objective} onChange={e => setObjective(e.target.value)} rows={4} placeholder="The purpose of this application is to do this thing." />
          </Form.Item>
          <Form.Item label="Application Context" required>
            <List
              dataSource={contextEntries}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Input
                    style={{ width: '30%', marginRight: '1em' }}
                    value={item.context_key}
                    onChange={(e) => handleContextKeyChange(item.id, e.target.value)}
                  />
                  <Input.TextArea
                    style={{ width: '65%', marginRight: '1em' }}
                    value={item.context_value}
                    onChange={(e) => handleContextValueChange(item.id, e.target.value)}
                    rows={1}
                  />
                  <Button onClick={() => handleRemoveContextEntry(item.id)}>-</Button>
                </List.Item>
              )}
            />
            <List.Item>
              <Button type="dashed" onClick={handleAddContextEntry} block>
                + Add Context Entry
              </Button>
            </List.Item>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit} block loading={loading} disabled={loading}>Clone Application</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CloneApplication;

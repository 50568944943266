import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Form, Input, Card, notification, List } from 'antd';
import { ApplicationService } from '../services/ApplicationService';
import Application from '../models/Application';
import './EditApplication.css';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationObjective from '../models/ApplicationObjective';
import { usePermissions } from '../PermissionsProvider';


const EditApplication: React.FC = () => {
  const navigate = useNavigate();  
  const { apiKey } = usePermissions();
  const contextEntryIdCounter = useRef(0);
  const [contextEntries, setContextEntries] = useState<{ id: number; context_key: string; context_value: string }[]>([]);
  const [editKey, setEditKey] = useState<string | null>(null);
  const { applicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [applicationRefName, setApplicationRefName] = useState('');
  const [objective, setObjective] = useState('');
  const [application, setApplication] = useState<Application | undefined>();

  useEffect(() => {
    if (applicationId) {
        setLoading(true);
        ApplicationService.getApplication(Number(applicationId), setLoading, apiKey)
        .subscribe((application: Application) => {
          setApplication(application);
          setApplicationName(application.application_name);
          setApplicationRefName(application.application_ref_name);
          setObjective(application.application_objective?.objective_body['main'] || '');
          setLoading(false);
        }, (error: any) => {
          console.error(error);
          notification.error({
            message: 'Error',
            description: 'Failed to fetch application',
          });
          setLoading(false);
        });
        ApplicationService.getApplicationContext(Number(applicationId), null, apiKey)
            .subscribe((context: any) => {
                setContextEntries(
                    Object.entries(context.context_body || {}).map(([context_key, context_value]) => ({
                      id: contextEntryIdCounter.current++,
                      context_key,
                      context_value: String(context_value),
                    })));
                }, (error: any) => {
                console.error(error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch application context',
                });
        });
    }
  }, [applicationId]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    if (application) {
      application.application_name = applicationName;
      application.application_ref_name = applicationRefName;
      if (!application.application_objective) {
        application.application_objective = new ApplicationObjective();
      }
      application.application_objective.objective_body = { main: objective };
    }

    ApplicationService.updateApplication(application, setLoading, apiKey).subscribe(
      () => {
        notification.success({
          message: 'Success',
          description: 'Application updated successfully',
        });
        navigate('/');
      },
      (error: any) => {
        console.error(error);
        notification.error({
          message: 'Error',
          description: 'Failed to update application',
        });
        setLoading(false);
      }
    );

    const updatedContextBody = contextEntries.reduce(
      (acc, { context_key, context_value }) => ({ ...acc, [context_key]: context_value }),
      {}
    );
    const payload = { application_id: applicationId, context: updatedContextBody };
    setLoading(true);
    ApplicationService.updateApplicationContext(payload, setLoading, apiKey).subscribe(() => {
      notification.success({
        message: 'Success',
        description: 'Application context updated successfully',
      });
    });
  }, [application, applicationName, applicationRefName, objective, contextEntries, applicationId, navigate]);

  const handleContextKeyChange = useCallback((itemId: number, newContextKey: string) => {
    setContextEntries((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, context_key: newContextKey } : entry))
    );
  }, []);

  const handleContextValueChange = useCallback((itemId: number, newContextValue: string) => {
    setContextEntries((prev) =>
      prev.map((entry) => (entry.id === itemId ? { ...entry, context_value: newContextValue } : entry))
    );
  }, []);

  const handleRemoveContextEntry = useCallback((itemId: number) => {
    setContextEntries((prev) => prev.filter((entry) => entry.id !== itemId));
  }, []);

  const handleAddContextEntry = useCallback(() => {
    setContextEntries((prev) => [
      ...prev,
      { id: Date.now() + Math.random(), context_key: '', context_value: '' },
    ]);
  }, []);

  return (
    <div className="edit-application-container">
      <Card title="Edit Application" bordered={false} className="edit-application-card">
        <Form layout="vertical" disabled={loading}>
          <Form.Item label="Name" required tooltip="The name of the application">
            <Input value={applicationName} onChange={e => setApplicationName(e.target.value)} placeholder="My Application" />
          </Form.Item>
          <Form.Item label="Application Reference" required tooltip="The reference name of the application">
            <Input value={applicationRefName} onChange={e => setApplicationRefName(e.target.value)} placeholder="my_application" />
          </Form.Item>
          <Form.Item label="Objective" required tooltip="The objective of the application">
            <Input.TextArea value={objective} onChange={e => setObjective(e.target.value)} rows={4} placeholder="The purpose of this application is to do this thing." />
          </Form.Item>
          <Form.Item label="Application Context" required>
            <List
              dataSource={contextEntries}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Input
                    style={{ width: '30%', marginRight: '1em' }}
                    value={item.context_key}
                    onChange={(e) => handleContextKeyChange(item.id, e.target.value)}
                  />
                  <Input.TextArea
                    style={{ width: '65%', marginRight: '1em' }}
                    value={item.context_value}
                    onChange={(e) => handleContextValueChange(item.id, e.target.value)}
                    rows={1}
                  />
                  <Button onClick={() => handleRemoveContextEntry(item.id)}>-</Button>
                </List.Item>
              )}
            />
            <List.Item>
              <Button type="dashed" onClick={handleAddContextEntry} block>
                + Add Context Entry
              </Button>
            </List.Item>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit} block loading={loading} disabled={loading}>Update Application</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default EditApplication;

import GeneralService from './GeneralService'
import {Component} from 'react'


export class EvaluationService extends Component {
  public static getApplicationSessions(applicationId: any, offset: number, limit: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/evaluation/sessions/application/${applicationId}/${offset}/${limit}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }
}

